td {
    border-radius: 0px;
    overflow-wrap: break-word;
}
table {
    table-layout: fixed;
}

.left-align {
    text-align: left;
}

.right-align {
    text-align: right;
}

.center,
.center-align {
    text-align: center;
}

.left {
    float: left !important;
}

.right {
    float: right !important;
}

body {
    margin: 0;
}
